import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styles from "./loading.module.scss"
import SvgIcon from "../SvgIcon"
import BudCover from "../TextBudStage/"
import ProgressBar from "../Home/components/ProgressBar"
import latascorriendo from "../../../images/BudStage/ilushome.png"

const Loading = ({ progress, loading }) => {
  const show = loading ? styles.show : ""

  return (
    <div className={`${styles.loading} ${show}`}>
      <BudCover orientation="vertical" className={styles.budTextVertical} />
      <SvgIcon name="loading-text-icon" className={styles.loadingTextLeft} />
      <SvgIcon name="bud-whiteform-icon" className={styles.whiteFormIcon} />
      <div className={styles.content}>

        <div className={styles.logo}>
          <img src={latascorriendo} />
        </div>
        <p>¡ESTAMOS CARGANDO TU VIDEO!</p>
        <ProgressBar loaded={progress} width="25.833vw" />
      </div>
      <SvgIcon
        name="we-brew-icon"
        size="4.290vw"
        className={styles.weBrewIcon}
      />
      <SvgIcon name="loading-text-icon" className={styles.loadingTextRight} />
    </div>
  )
}

export default Loading
