import React, { useEffect, useState } from 'react';
import styles from './progressbar.module.scss'

const ProgressBar = ({ width, loaded }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(Math.round((loaded / 380) * 100));
  }, [loaded]);


  return (
    <div className={styles.progressBar} style={{ width: width, alignSelf: 'center' }}>
      <div style={{ width: `${progress}vw` }} className={styles.progress} />
      <span className={styles.progressBottom}>{loaded}%</span>
    </div>
  )
}

export default ProgressBar;
